import { createReducer, on } from '@ngrx/store';
import { DashboardActions } from './dashboard.actions';
import { DealerInfo, FirmwareInfo, OnlineInfo, SignalInfo } from '../../../shared/interfaces/dashboard.interfaces';

export const dashboardFeatureKey = 'dashboard';

export interface DashboardState {
  isLoading: boolean;
  error: unknown;
  onlineInfo: OnlineInfo | null;
  signalInfo: SignalInfo | null;
  firmwareInfo: FirmwareInfo[] | [];
  dealerInfo: DealerInfo[] | []
}

export const initialState: DashboardState = {
  isLoading: false,
  error: null,
  onlineInfo: null,
  signalInfo: null,
  firmwareInfo: [],
  dealerInfo: []
};

export const dashboardReducer = createReducer(
  initialState,

  on(DashboardActions.loadOnlineInfo, state => { return { ...state, isLoading: true } }),
  on(DashboardActions.loadOnlineInfoSuccess, (state, action) => { return { ...state, onlineInfo: action.data, isLoading: false, error: false } }),
  on(DashboardActions.loadOnlineInfoFailure, (state, action) => { return { ...state, error: action.error, isLoading: false } }),

  on(DashboardActions.loadSignalInfo, state => { return { ...state, isLoading: true } }),
  on(DashboardActions.loadSignalInfoSuccess, (state, action) => { return { ...state, signalInfo: action.data, isLoading: false, error: false } }),
  on(DashboardActions.loadSignalInfoFailure, (state, action) => { return { ...state, error: action.error, isLoading: false } }),

  on(DashboardActions.loadFirmwareInfo, state => { return { ...state, isLoading: true } }),
  on(DashboardActions.loadFirmwareInfoSuccess, (state, action) => { return { ...state, firmwareInfo: action.data, isLoading: false, error: false } }),
  on(DashboardActions.loadFirmwareInfoFailure, (state, action) => { return { ...state, error: action.error, isLoading: false } }),

  on(DashboardActions.loadDealerInfo, state => { return { ...state, isLoading: true } }),
  on(DashboardActions.loadDealerInfoSuccess, (state, action) => { return { ...state, dealerInfo: action.data, isLoading: false, error: false } }),
  on(DashboardActions.loadDealerInfoFailure, (state, action) => { return { ...state, error: action.error, isLoading: false } }),

  on(DashboardActions.unsetDashboard, () => initialState)
)
