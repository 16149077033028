import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DealerInfo, FirmwareInfo, OnlineInfo, SignalInfo } from '../../../shared/interfaces/dashboard.interfaces';

export const DashboardActions = createActionGroup({
  source: 'Dashboard',
  events: {
    'Load Online Info': emptyProps(),
    'Load Online Info Success': props<{ data: OnlineInfo }>(),
    'Load Online Info Failure': props<{ error: unknown }>(),
    
    'Load Signal Info': emptyProps(),
    'Load Signal Info Success': props<{ data: SignalInfo }>(),
    'Load Signal Info Failure': props<{ error: unknown }>(),
    
    'Load Firmware Info': emptyProps(),
    'Load Firmware Info Success': props<{ data: FirmwareInfo[] }>(),
    'Load Firmware Info Failure': props<{ error: unknown }>(),
    
    'Load Dealer Info': emptyProps(),
    'Load Dealer Info Success': props<{ data: DealerInfo[] }>(),
    'Load Dealer Info Failure': props<{ error: unknown }>(),
    
    'Unset Dashboard': emptyProps(),
  }
});
