import { createFeature, createReducer, on } from '@ngrx/store';
import { DealerActions } from './dealer.actions';
import { ChildrenDealer, Dealer, DealerUser } from '../../../shared/interfaces/dealer.interfaces';

export const dealerFeatureKey = 'dealer';

export interface DealerState {
  isLoading: boolean;
  error: unknown;
  dealer: Dealer[] | null;
  dealerUsers: DealerUser[] | []
  childrenDealers: ChildrenDealer[] | []
}

export const initialState: DealerState = {
  isLoading: false,
  error: null,
  dealer: null,
  dealerUsers: [],
  childrenDealers: []
};

export const dealerReducer = createReducer(
  initialState,

  on(DealerActions.loadDealer, state => { return { ...state, isLoading: true } }),
  on(DealerActions.loadDealerSuccess, (state, action) => { return { ...state, isLoading: false, error: false, dealer: action.data } }),
  on(DealerActions.loadDealerFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(DealerActions.loadUsers, state => { return { ...state, isLoading: true } }),
  on(DealerActions.loadUsersSuccess, (state, action) => { return { ...state, isLoading: false, error: false, dealerUsers: action.data } }),
  on(DealerActions.loadUsersFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),
  on(DealerActions.unsetUsers, (state) => { return { ...state, dealerUsers: [] } }),

  on(DealerActions.loadChildrenDealers, state => { return { ...state, isLoading: true } }),
  on(DealerActions.loadChildrenDealersSuccess, (state, action) => { return { ...state, isLoading: false, error: false, childrenDealers: action.data } }),
  on(DealerActions.loadChildrenDealersFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),
  on(DealerActions.unsetChildrenDealers, (state) => { return { ...state, childrenDealers: [] } }),

  on(DealerActions.unsetDealer, () => initialState)
)
